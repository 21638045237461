import {
  Card,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import {
  GridLayout,
  Step,
  TableCellCustom,
  TypographyCustom,
} from "../../components";
import { routePath } from "../../routes/routePath";

export const pageInfoInfo = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "ご利用案内", url: routePath.info },
  ],
  pageHeader: "ご利用案内",
};

export const buttonListInfoPage = [
  { name: "ご利用までの流れ" },
  {
    name: "利用料金",
  },
  {
    name: "事業所案内",
  },
  {
    name: "プライベート看護",
    url: routePath.privacy,
  },
];

const listSteps = [
  {
    height: 120,
    header: "担当のケアマネジャーにサービスの利用を相談しよう",
    content:
      "まずは担当のケアマネジャーに現状困っていることを伝え、訪問看護の利用を検討してもらいましょう。",
  },
  {
    height: 120,
    header:
      "サービスの利用が決まったら、ケアマネジャーがサービス提供事業者へ連絡をし、サービス提供の可否を確認します",
    content:
      "連絡を受けたサービス提供事業者は、ご利用者様の住所や介護状況などからサービスの提供が可能かどうかを確認します。",
  },
  {
    height: 140,
    header:
      "サービスを提供してくれる事業者が決定したら、その事業者からご利用者様の主治医へ訪問看護指示書の発行が依頼されます",
    content:
      "サービスを提供することになった事業者は、ご利用者様の主治医へ訪問看護指示書を依頼し、依頼を受けた医師は事業所宛に訪問看護指示書を送ります。",
  },
  {
    height: 140,
    header:
      "担当のケアマネジャー、サービス提供事業者の担当者と一緒にケアプランを作成します",
    content:
      "ご利用者様の状態や介護保険給付の限度額を考慮しながら、利用頻度やサービス内容など、ご利用者様にとって最適なケアプランを作成します。",
  },
  {
    height: 140,
    header:
      "ケアプランが完成したら、サービス提供事業者と契約し、サービスの利用開始です",
    content:
      "ケアプランができたら、最後は事業者との契約を経てサービス利用開始となります。サービス利用開始日は、担当のケアマネジャーと事業者の担当者と話し合って決定されます。",
  },
];

export const topicInfo = [
  {
    header: "ご利用までの流れ",
    type: "custom",
    content: (
      <GridLayout md={[12]} xs={[12]}>
        {listSteps.map((step, index) => {
          return (
            <Step
              info={step}
              stepNo={index + 1}
              isEnd={index === listSteps.length - 1}
            ></Step>
          );
        })}
      </GridLayout>
    ),
  },
  {
    header: "利用料金",
    content: [
      "・利用する公的保険の種類によって基本利用料の割合が異なります。詳細はお気軽にお問い合わせください。",
      "・医療保険",
      "　→指定訪問看護を提供した場合は、老人保健法および健康保険法に定められた基準によります。",
      "（使用する保険および個人所得によって負担割合が異なります。）",
      "・介護保険",
      "　→介護保険法で定められた金額によります。",
      "・ご利用料金は下記ページでご確認いただくこともできます。",
      <>
        <Link
          style={{ marginLeft: "16px" }}
          href={routePath.longTermCareInsurance}
        >
          介護保険
        </Link>
        <Link style={{ marginLeft: "16px" }} href={routePath.medicalInsurance}>
          医療保険
        </Link>
        <Link
          style={{ marginLeft: "16px", display: "inline-block" }}
          href={routePath.nursingCareInsurance}
        >
          {/*介護保険（定期巡回）*/}
        </Link>
      </>,
    ],
  },
  {
    header: "事業所案内",
    type: "custom",
    content: (
      <>
        <TableContainer component={Card} style={{ marginBottom: "32px" }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCellCustom width={"100px"}>
                  <TypographyCustom>住所</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom align="left">
                  <TypographyCustom>
                    732-0824 広島県広島市南区的場町2丁目6-3 JH的場301号室
                  </TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom>
                  <TypographyCustom>TEL</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom align="left">
                  <TypographyCustom>082-909-2920</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom>
                  <TypographyCustom>FAX</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom align="left">
                  <TypographyCustom>082-554-0833</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1646.1908217881198!2d132.4745227635748!3d34.391644325203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDIzJzMwLjciTiAxMzLCsDI4JzMyLjAiRQ!5e0!3m2!1sja!2s!4v1701098773232!5m2!1sja!2s"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="住所"
        ></iframe>
      </>
    ),
  },
];
