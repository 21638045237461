import {
  pageInfoDX,
  topicDX,
} from "../../PageData/DX/DXPage";
import { GridLayout, PageBodyContainer, Topic } from "../../components";
const DX = () => {
  return (
    <PageBodyContainer pageInfo={pageInfoDX}>
      <GridLayout md={[12]} spacing={6} style={{ marginTop: 32 }}>
        {<Topic topic={topicDX} id={`dxTopic`}></Topic>}
      </GridLayout>
    </PageBodyContainer>
  );
};

export default DX;
