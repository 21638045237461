import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  GridLayout,
  TableCellCustom,
  TypographyCustom,
} from "../../components";
import { routePath } from "../../routes/routePath";

export const pageInfoMedicalInsurance = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "ご利用案内", url: routePath.info },
    { name: "利用料金", url: routePath.info, id: "infoPageTopic1" },
    { name: "医療保険", url: routePath.medicalInsurance },
  ],
  pageHeader: "医療保険",
};

const style = {
  headerCell: {
    backgroundColor: "#F3EEEA",
  },
};

const table1 = [
  [
    { rowSpan: 2, colSpan: 1, data: "□" },
    {
      rowSpan: 2,
      colSpan: 1,
      data: (
        <>
          訪問看護基本療養費（Ⅰ）
          <br />
          （保健師・助産師・看護師による場合）
        </>
      ),
    },
    { rowSpan: 1, colSpan: 2, data: "週3日目まで" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "5,550円" },
    { rowSpan: 1, colSpan: 1, data: "555円" },
    { rowSpan: 1, colSpan: 1, data: "1,110円" },
    { rowSpan: 1, colSpan: 1, data: "1,665円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 2, data: "週4日目以降" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "6,550円" },
    { rowSpan: 1, colSpan: 1, data: "655円" },
    { rowSpan: 1, colSpan: 1, data: "1,310円" },
    { rowSpan: 1, colSpan: 1, data: "1,965円" },
  ],
  [
    { rowSpan: 2, colSpan: 1, data: "□" },
    {
      rowSpan: 2,
      colSpan: 1,
      data: (
        <>
          訪問看護基本療養費（Ⅰ）
          <br />
          （准看護師による場合）
        </>
      ),
    },
    { rowSpan: 1, colSpan: 2, data: "週3日目まで" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "5,050円" },
    { rowSpan: 1, colSpan: 1, data: "505円" },
    { rowSpan: 1, colSpan: 1, data: "1,010円" },
    { rowSpan: 1, colSpan: 1, data: "1,515円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 2, data: "週4日目以降" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "6,050円" },
    { rowSpan: 1, colSpan: 1, data: "605円" },
    { rowSpan: 1, colSpan: 1, data: "1,210円" },
    { rowSpan: 1, colSpan: 1, data: "1,815円" },
  ],
  [
    { rowSpan: 4, colSpan: 1, data: "□" },
    {
      rowSpan: 4,
      colSpan: 1,
      data: (
        <>
          訪問看護基本療養費（Ⅱ）
          <br />
          （同一建物居住者）
          <br />
          （保健師・助産師・看護師による場合）
        </>
      ),
    },
    { rowSpan: 2, colSpan: 1, data: "同一日に2人まで" },
    { rowSpan: 1, colSpan: 1, data: "週3日目まで" },
    { rowSpan: 1, colSpan: 1, data: "5,550円" },
    { rowSpan: 1, colSpan: 1, data: "555円" },
    { rowSpan: 1, colSpan: 1, data: "1,110円" },
    { rowSpan: 1, colSpan: 1, data: "1,665円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "週4日目以降" },
    { rowSpan: 1, colSpan: 1, data: "6,550円" },
    { rowSpan: 1, colSpan: 1, data: "655円" },
    { rowSpan: 1, colSpan: 1, data: "1,310円" },
    { rowSpan: 1, colSpan: 1, data: "1,965円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 2, colSpan: 1, data: "同一日に3人以上" },
    { rowSpan: 1, colSpan: 1, data: "週3日目まで" },
    { rowSpan: 1, colSpan: 1, data: "2,780円" },
    { rowSpan: 1, colSpan: 1, data: "278円" },
    { rowSpan: 1, colSpan: 1, data: "556円" },
    { rowSpan: 1, colSpan: 1, data: "834円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "週4日目以降" },
    { rowSpan: 1, colSpan: 1, data: "3,280円" },
    { rowSpan: 1, colSpan: 1, data: "328円" },
    { rowSpan: 1, colSpan: 1, data: "656円" },
    { rowSpan: 1, colSpan: 1, data: "984円" },
  ],
  [
    { rowSpan: 4, colSpan: 1, data: "□" },
    {
      rowSpan: 4,
      colSpan: 1,
      data: (
        <>
          訪問看護基本療養費（Ⅱ）
          <br />
          （同一建物居住者）
          <br />
          （准看護師による場合）
        </>
      ),
    },
    { rowSpan: 2, colSpan: 1, data: "同一日に2人まで" },
    { rowSpan: 1, colSpan: 1, data: "週3日目まで" },
    { rowSpan: 1, colSpan: 1, data: "5,050円" },
    { rowSpan: 1, colSpan: 1, data: "505円" },
    { rowSpan: 1, colSpan: 1, data: "1,010円" },
    { rowSpan: 1, colSpan: 1, data: "1,515円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "週4日目以降" },
    { rowSpan: 1, colSpan: 1, data: "6,050円" },
    { rowSpan: 1, colSpan: 1, data: "605円" },
    { rowSpan: 1, colSpan: 1, data: "1,210円" },
    { rowSpan: 1, colSpan: 1, data: "1,815円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 2, colSpan: 1, data: "同一日に3人以上" },
    { rowSpan: 1, colSpan: 1, data: "週3日目まで" },
    { rowSpan: 1, colSpan: 1, data: "2,530円" },
    { rowSpan: 1, colSpan: 1, data: "253円" },
    { rowSpan: 1, colSpan: 1, data: "506円" },
    { rowSpan: 1, colSpan: 1, data: "759円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "週4日目以降" },
    { rowSpan: 1, colSpan: 1, data: "3,030円" },
    { rowSpan: 1, colSpan: 1, data: "303円" },
    { rowSpan: 1, colSpan: 1, data: "606円" },
    { rowSpan: 1, colSpan: 1, data: "909円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "□" },
    { rowSpan: 1, colSpan: 1, data: "訪問看護基本療養費（Ⅲ）" },
    { rowSpan: 1, colSpan: 2, data: "入院中の外泊時" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "8,500円" },
    { rowSpan: 1, colSpan: 1, data: "850円" },
    { rowSpan: 1, colSpan: 1, data: "1,700円" },
    { rowSpan: 1, colSpan: 1, data: "2,550円" },
  ],
];

const table2 = [
  [
    { rowSpan: 2, colSpan: 1, data: "□" },
    { rowSpan: 2, colSpan: 1, data: "訪問看護管理療養費" },
    { rowSpan: 1, colSpan: 3, data: "月の初日" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "7,670円" },
    { rowSpan: 1, colSpan: 1, data: "767円" },
    { rowSpan: 1, colSpan: 1, data: "1,534円" },
    { rowSpan: 1, colSpan: 1, data: "2,301円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 3, data: "2日以降" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "3,000円" },
    { rowSpan: 1, colSpan: 1, data: "300円" },
    { rowSpan: 1, colSpan: 1, data: "600円" },
    { rowSpan: 1, colSpan: 1, data: "900円" },
  ],
];

const table3 = [
  [
    { rowSpan: 1, colSpan: 1, data: "①" },
    { rowSpan: 1, colSpan: 1, data: "24時間対応体制加算（月1回）" },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "6,520円" },
    { rowSpan: 1, colSpan: 1, data: "652円" },
    { rowSpan: 1, colSpan: 1, data: "1,304円" },
    { rowSpan: 1, colSpan: 1, data: "1,956円" },
  ],
  
  [
  { rowSpan: 2, colSpan: 1, data: "②" },
  {
    rowSpan: 2,
    colSpan: 1,
    data: (
      <>
        緊急訪問看護加算（1日につき）
      </>
    ),
  },
  { rowSpan: 1, colSpan: 1, data: "月14日目まで" },
  { rowSpan: 1, colSpan: 1, data: " " },
  { rowSpan: 1, colSpan: 1, data: "2,650円" },
  { rowSpan: 1, colSpan: 1, data: "265円" },
  { rowSpan: 1, colSpan: 1, data: "530円" },
  { rowSpan: 1, colSpan: 1, data: "795円" },
 ],
 [
  { rowSpan: 1, colSpan: 1, data: "" },
  { rowSpan: 1, colSpan: 1, data: "" },
  { rowSpan: 1, colSpan: 1, data: "" },
  { rowSpan: 1, colSpan: 1, data: "月15日目以降" },
  { rowSpan: 1, colSpan: 1, data: " " },
  { rowSpan: 1, colSpan: 1, data: "2,000円" },
  { rowSpan: 1, colSpan: 1, data: "200円" },
  { rowSpan: 1, colSpan: 1, data: "400円" },
  { rowSpan: 1, colSpan: 1, data: "600円" },
 ],


  [
    { rowSpan: 2, colSpan: 1, data: "③" },
    {
      rowSpan: 2,
      colSpan: 1,
      data: (
        <>
          夜間・早朝訪問看護加算
          <br />
          （1回につき）
        </>
      ),
    },
    { rowSpan: 1, colSpan: 2, data: "夜間（18時～22時）" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 2, colSpan: 1, data: "2,100円" },
    { rowSpan: 2, colSpan: 1, data: "210円" },
    { rowSpan: 2, colSpan: 1, data: "420円" },
    { rowSpan: 2, colSpan: 1, data: "630円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 2, data: "早朝（6時～8時）" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "④" },
    { rowSpan: 1, colSpan: 1, data: "深夜訪問看護加算（1回につき）" },
    { rowSpan: 1, colSpan: 2, data: "深夜（22時～翌朝6時）" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "4,200円" },
    { rowSpan: 1, colSpan: 1, data: "420円" },
    { rowSpan: 1, colSpan: 1, data: "840円" },
    { rowSpan: 1, colSpan: 1, data: "1,260円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑤" },
    { rowSpan: 1, colSpan: 1, data: "特別管理加算（Ⅰ）（月1回）" },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "5,000円" },
    { rowSpan: 1, colSpan: 1, data: "500円" },
    { rowSpan: 1, colSpan: 1, data: "1,000円" },
    { rowSpan: 1, colSpan: 1, data: "1,500円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑥" },
    { rowSpan: 1, colSpan: 1, data: "特別管理加算（Ⅱ）（月1回）" },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "2,500円" },
    { rowSpan: 1, colSpan: 1, data: "250円" },
    { rowSpan: 1, colSpan: 1, data: "500円" },
    { rowSpan: 1, colSpan: 1, data: "750円" },
  ],
  [
    { rowSpan: 4, colSpan: 1, data: "⑦" },
    { rowSpan: 4, colSpan: 1, data: "難病等複数回訪問加算" },
    { rowSpan: 2, colSpan: 1, data: "1日2回" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内1人又は2人" },
    { rowSpan: 1, colSpan: 1, data: "4,500円" },
    { rowSpan: 1, colSpan: 1, data: "450円" },
    { rowSpan: 1, colSpan: 1, data: "900円" },
    { rowSpan: 1, colSpan: 1, data: "1,350円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内3人以上" },
    { rowSpan: 1, colSpan: 1, data: "4,000円" },
    { rowSpan: 1, colSpan: 1, data: "400円" },
    { rowSpan: 1, colSpan: 1, data: "800円" },
    { rowSpan: 1, colSpan: 1, data: "1,200円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 2, colSpan: 1, data: "1日3回以上" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内1人又は2人" },
    { rowSpan: 1, colSpan: 1, data: "8,000円" },
    { rowSpan: 1, colSpan: 1, data: "800円" },
    { rowSpan: 1, colSpan: 1, data: "1,600円" },
    { rowSpan: 1, colSpan: 1, data: "2,400円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内3人以上" },
    { rowSpan: 1, colSpan: 1, data: "7,200円" },
    { rowSpan: 1, colSpan: 1, data: "720円" },
    { rowSpan: 1, colSpan: 1, data: "1,440円" },
    { rowSpan: 1, colSpan: 1, data: "2,160円" },
  ],
  [
    { rowSpan: 12, colSpan: 1, data: "⑧" },
    { rowSpan: 12, colSpan: 1, data: "複数名訪問看護加算" },
    {
      rowSpan: 2,
      colSpan: 1,
      data: (
        <>
          看護師等の場合
          <br />
          （週1日）
        </>
      ),
    },
    { rowSpan: 1, colSpan: 1, data: "同一建物内1人又は2人" },
    { rowSpan: 1, colSpan: 1, data: "4,500円" },
    { rowSpan: 1, colSpan: 1, data: "450円" },
    { rowSpan: 1, colSpan: 1, data: "900円" },
    { rowSpan: 1, colSpan: 1, data: "1,350円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内3人以上" },
    { rowSpan: 1, colSpan: 1, data: "4,000円" },
    { rowSpan: 1, colSpan: 1, data: "400円" },
    { rowSpan: 1, colSpan: 1, data: "800円" },
    { rowSpan: 1, colSpan: 1, data: "1,200円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    {
      rowSpan: 2,
      colSpan: 1,
      data: (
        <>
          准看護師等の場合
          <br />
          （週1日）
        </>
      ),
    },
    { rowSpan: 1, colSpan: 1, data: "同一建物内1人又は2人" },
    { rowSpan: 1, colSpan: 1, data: "3,800円" },
    { rowSpan: 1, colSpan: 1, data: "380円" },
    { rowSpan: 1, colSpan: 1, data: "760円" },
    { rowSpan: 1, colSpan: 1, data: "1,140円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内3人以上" },
    { rowSpan: 1, colSpan: 1, data: "3,400円" },
    { rowSpan: 1, colSpan: 1, data: "340円" },
    { rowSpan: 1, colSpan: 1, data: "680円" },
    { rowSpan: 1, colSpan: 1, data: "1,020円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    {
      rowSpan: 2,
      colSpan: 1,
      data: (
        <>
          その他職員の場合
          <br />
          （看護師等・看護補助者）
          <br />
          （週3日まで）
        </>
      ),
    },
    { rowSpan: 1, colSpan: 1, data: "同一建物内1人又は2人" },
    { rowSpan: 1, colSpan: 1, data: "3,000円" },
    { rowSpan: 1, colSpan: 1, data: "300円" },
    { rowSpan: 1, colSpan: 1, data: "600円" },
    { rowSpan: 1, colSpan: 1, data: "900円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内3人以上" },
    { rowSpan: 1, colSpan: 1, data: "2,700円" },
    { rowSpan: 1, colSpan: 1, data: "270円" },
    { rowSpan: 1, colSpan: 1, data: "540円" },
    { rowSpan: 1, colSpan: 1, data: "810円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    {
      rowSpan: 2,
      colSpan: 1,
      data: (
        <>
          その他職員（看護師等・看護補助者）の場合
          <br />
          （別に厚生労働大臣が定める場合）
          <br />
          1日1回
        </>
      ),
    },
    { rowSpan: 1, colSpan: 1, data: "同一建物内1人又は2人" },
    { rowSpan: 1, colSpan: 1, data: "3,000円" },
    { rowSpan: 1, colSpan: 1, data: "300円" },
    { rowSpan: 1, colSpan: 1, data: "600円" },
    { rowSpan: 1, colSpan: 1, data: "900円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内3人以上" },
    { rowSpan: 1, colSpan: 1, data: "2,700円" },
    { rowSpan: 1, colSpan: 1, data: "270円" },
    { rowSpan: 1, colSpan: 1, data: "540円" },
    { rowSpan: 1, colSpan: 1, data: "810円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    {
      rowSpan: 2,
      colSpan: 1,
      data: (
        <>
          その他職員（看護師等・看護補助者）の場合
          <br />
          （別に厚生労働大臣が定める場合）
          <br />
          1日2回
        </>
      ),
    },
    { rowSpan: 1, colSpan: 1, data: "同一建物内1人又は2人" },
    { rowSpan: 1, colSpan: 1, data: "6,000円" },
    { rowSpan: 1, colSpan: 1, data: "600円" },
    { rowSpan: 1, colSpan: 1, data: "1,200円" },
    { rowSpan: 1, colSpan: 1, data: "1,800円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内3人以上" },
    { rowSpan: 1, colSpan: 1, data: "5,400円" },
    { rowSpan: 1, colSpan: 1, data: "540円" },
    { rowSpan: 1, colSpan: 1, data: "1,080円" },
    { rowSpan: 1, colSpan: 1, data: "1,620円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    {
      rowSpan: 2,
      colSpan: 1,
      data: (
        <>
          その他職員（看護師等・看護補助者）の場合
          <br />
          （別に厚生労働大臣が定める場合）
          <br />
          1日3回以上
        </>
      ),
    },
    { rowSpan: 1, colSpan: 1, data: "同一建物内1人又は2人" },
    { rowSpan: 1, colSpan: 1, data: "10,000円" },
    { rowSpan: 1, colSpan: 1, data: "1,000円" },
    { rowSpan: 1, colSpan: 1, data: "2,000円" },
    { rowSpan: 1, colSpan: 1, data: "3,000円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "同一建物内3人以上" },
    { rowSpan: 1, colSpan: 1, data: "9,000円" },
    { rowSpan: 1, colSpan: 1, data: "900円" },
    { rowSpan: 1, colSpan: 1, data: "1,800円" },
    { rowSpan: 1, colSpan: 1, data: "2,700円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑨" },
    { rowSpan: 1, colSpan: 1, data: "長時間訪問看護加算" },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "5,200円" },
    { rowSpan: 1, colSpan: 1, data: "520円" },
    { rowSpan: 1, colSpan: 1, data: "1,040円" },
    { rowSpan: 1, colSpan: 1, data: "1,560円" },
  ],
  [ 
    { rowSpan: 2, colSpan: 1, data: "⑩" },
     {
       rowSpan: 2,
       colSpan: 1,
       data: (
         <>
           乳幼児加算（1日につき）
         </>
       ),
     },
     { rowSpan: 1, colSpan: 1, data: "厚生労働大臣が定める者" },
     { rowSpan: 1, colSpan: 1, data: " " },
     { rowSpan: 1, colSpan: 1, data: "1,800円" },
     { rowSpan: 1, colSpan: 1, data: "180円" },
     { rowSpan: 1, colSpan: 1, data: "360円" },
     { rowSpan: 1, colSpan: 1, data: "540円" },
  ],
  [
     { rowSpan: 1, colSpan: 1, data: "" },
     { rowSpan: 1, colSpan: 1, data: "" },
     { rowSpan: 1, colSpan: 1, data: "" },
     { rowSpan: 1, colSpan: 1, data: "上記以外の者" },
     { rowSpan: 1, colSpan: 1, data: " " },
     { rowSpan: 1, colSpan: 1, data: "1,300円" },
     { rowSpan: 1, colSpan: 1, data: "130円" },
     { rowSpan: 1, colSpan: 1, data: "260円" },
     { rowSpan: 1, colSpan: 1, data: "390円" },
  ],
  [
    { rowSpan: 2, colSpan: 1, data: "⑪" },
    { rowSpan: 2, colSpan: 1, data: "退院時共同指導加算" },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "8,000円" },
    { rowSpan: 1, colSpan: 1, data: "800円" },
    { rowSpan: 1, colSpan: 1, data: "1,600円" },
    { rowSpan: 1, colSpan: 1, data: "2,400円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 2, data: "特別管理指導加算" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "2,000円" },
    { rowSpan: 1, colSpan: 1, data: "200円" },
    { rowSpan: 1, colSpan: 1, data: "400円" },
    { rowSpan: 1, colSpan: 1, data: "600円" },
  ],
  [
    { rowSpan: 2, colSpan: 1, data: "⑫" },
    { rowSpan: 2, colSpan: 1, data: "退院支援指導加算（退院日の訪問時）" },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "6,000円" },
    { rowSpan: 1, colSpan: 1, data: "600円" },
    { rowSpan: 1, colSpan: 1, data: "1,200円" },
    { rowSpan: 1, colSpan: 1, data: "1,800円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 2, data: "長時間にわたる療養上必要な指導の場合" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "8,400円" },
    { rowSpan: 1, colSpan: 1, data: "840円" },
    { rowSpan: 1, colSpan: 1, data: "1,680円" },
    { rowSpan: 1, colSpan: 1, data: "2,520円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑬" },
    { rowSpan: 1, colSpan: 1, data: "在宅患者連携指導加算（月1回）" },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "3,000円" },
    { rowSpan: 1, colSpan: 1, data: "300円" },
    { rowSpan: 1, colSpan: 1, data: "600円" },
    { rowSpan: 1, colSpan: 1, data: "900円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑭" },
    {
      rowSpan: 1,
      colSpan: 1,
      data: "在宅患者緊急時等カンファレンス加算（月2回）",
    },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "2,000円" },
    { rowSpan: 1, colSpan: 1, data: "200円" },
    { rowSpan: 1, colSpan: 1, data: "400円" },
    { rowSpan: 1, colSpan: 1, data: "600円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑮" },
    { rowSpan: 1, colSpan: 1, data: "看護・介護職員連携強化加算(月1回）" },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "2,500円" },
    { rowSpan: 1, colSpan: 1, data: "250円" },
    { rowSpan: 1, colSpan: 1, data: "500円" },
    { rowSpan: 1, colSpan: 1, data: "750円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑯" },
    { rowSpan: 1, colSpan: 1, data: "訪問看護ターミナル療養費 １" },
    { rowSpan: 1, colSpan: 2, data: " " },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "25,000円" },
    { rowSpan: 1, colSpan: 1, data: "2,500円" },
    { rowSpan: 1, colSpan: 1, data: "5,000円" },
    { rowSpan: 1, colSpan: 1, data: "7,500円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑰" },
    { rowSpan: 1, colSpan: 1, data: "訪問看護ターミナル療養費 ２" },
    { rowSpan: 1, colSpan: 2, data: "特別養護老人ホーム" },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "10,000円" },
    { rowSpan: 1, colSpan: 1, data: "1,000円" },
    { rowSpan: 1, colSpan: 1, data: "2,000円" },
    { rowSpan: 1, colSpan: 1, data: "3,000円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑱" },
    { rowSpan: 1, colSpan: 1, data: "訪問看護情報提供療養費 １" },
    {
      rowSpan: 1,
      colSpan: 2,
      data: (
        <>
          市町村若しくは都道府県又は
          <br />
          指定特定・指定障害児相談支援事業者
        </>
      ),
    },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "1,500円" },
    { rowSpan: 1, colSpan: 1, data: "150円" },
    { rowSpan: 1, colSpan: 1, data: "300円" },
    { rowSpan: 1, colSpan: 1, data: "450円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑲" },
    { rowSpan: 1, colSpan: 1, data: "訪問看護情報提供療養費 ２" },
    {
      rowSpan: 1,
      colSpan: 2,
      data: "保育所・幼稚園、義務教育学校、高等学校  等",
    },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "1,500円" },
    { rowSpan: 1, colSpan: 1, data: "150円" },
    { rowSpan: 1, colSpan: 1, data: "300円" },
    { rowSpan: 1, colSpan: 1, data: "450円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "⑳" },
    { rowSpan: 1, colSpan: 1, data: "訪問看護情報提供療養費 ３" },
    {
      rowSpan: 1,
      colSpan: 2,
      data: "保険医療機関、介護老人保健施設、介護医療院",
    },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "1,500円" },
    { rowSpan: 1, colSpan: 1, data: "150円" },
    { rowSpan: 1, colSpan: 1, data: "300円" },
    { rowSpan: 1, colSpan: 1, data: "450円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "㉑" },
    { rowSpan: 1, colSpan: 1, data: "訪問看護医療DX情報活用加算（1月あたり）" },
    {
      rowSpan: 1,
      colSpan: 2,
      data: " ",
    },
    { rowSpan: 1, colSpan: 1, data: "" },
    { rowSpan: 1, colSpan: 1, data: "50円" },
    { rowSpan: 1, colSpan: 1, data: "5円" },
    { rowSpan: 1, colSpan: 1, data: "10円" },
    { rowSpan: 1, colSpan: 1, data: "15円" },
  ],
];


const TableHeadMedicalInsurance = (props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCellCustom
          width={700}
          style={style.headerCell}
          rowSpan={2}
          colSpan={props.colSpan}
        >
          <TypographyCustom>基本療養費</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} style={style.headerCell}>
          <TypographyCustom>基本利用料</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} style={style.headerCell} colSpan={3}>
          <TypographyCustom>利用者負担額（円）</TypographyCustom>
        </TableCellCustom>
      </TableRow>
      <TableRow>
        <TableCellCustom style={style.headerCell}>
          <TypographyCustom>（円）</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} style={style.headerCell}>
          <TypographyCustom>1割</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} style={style.headerCell}>
          <TypographyCustom>2割</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} style={style.headerCell}>
          <TypographyCustom>3割</TypographyCustom>
        </TableCellCustom>
      </TableRow>
    </TableHead>
  );
};

const TableBodyMedicalInsurance = (props) => {
  return (
    <TableBody>
      {props.rowData.map((row, index) => {
        return (
          <TableRow key={index}>
            {row.map((cell, cellIndex) => {
              if (cell.data === "") return null;
              return (
                <TableCellCustom
                  align={
                    cellIndex < props.align.length
                      ? props.align[cellIndex]
                      : "right"
                  }
                  key={cellIndex}
                  width={
                    cellIndex < props.width.length
                      ? props.width[cellIndex]
                      : undefined
                  }
                  rowSpan={cell.rowSpan}
                  colSpan={cell.colSpan}
                >
                  <TypographyCustom>{cell.data}</TypographyCustom>
                </TableCellCustom>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export const topicMedicalInsurance = [
  {
    header: "医療保険",
    type: "custom",
    content: (
      <GridLayout md={[12]} xs={[12]}>
        <TypographyCustom>
          健康保険法・国民健康法・後期高齢者医療に基づき利用者より所定の額（1割～3割）を徴収いたします。
        </TypographyCustom>
        <TypographyCustom>
          各種医療費公費負担の医療証をお持ちの方は、基本利用料が減免又は免除されます。
        </TypographyCustom>

        <TypographyCustom>
          <br />ア 基本利用料①（訪問看護基本療養費）
        </TypographyCustom>
        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "1200px", overflow: "scroll" }}>
            <TableHeadMedicalInsurance colSpan={4} />
            <TableBodyMedicalInsurance
              rowData={table1}
              align={["center", "left", "left", "left"]}
              width={["50px", "300px", "175px", "175px"]}
            />
          </Table>
        </TableContainer>

        <TypographyCustom>
          イ 基本利用料②（訪問看護管理療養費）
        </TypographyCustom>
        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "1200px", overflow: "scroll" }}>
            <TableHeadMedicalInsurance colSpan={5} />
            <TableBodyMedicalInsurance
              rowData={table2}
              align={["center", "left", "left", "center", "left"]}
              width={["50px", "300px", "120px", "110px", "120px"]}
            />
          </Table>
        </TableContainer>

        <TypographyCustom>
          ウ 加算及びその他の療養費（対象の方のみ）
        </TypographyCustom>
        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "1200px", overflow: "scroll" }}>
            <TableHeadMedicalInsurance colSpan={4} />
            <TableBodyMedicalInsurance
              rowData={table3}
              align={["center", "left", "left", "left"]}
              width={["50px", "300px", "175px", "175px"]}
            />
          </Table>
        </TableContainer>

      </GridLayout>
    ),
  },
];
