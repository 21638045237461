import { routePath } from "../../routes/routePath";

export const pageInfoDisclosureStatement = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "重要事項説明", url: routePath.disclosure },
  ],
  pageHeader: "重要事項説明",
};

export const topicDisclosureStatement = {
  header: "重要事項説明（医療保険/介護保険）※一部抜粋",
  content: [
    "高齢者虐待の防止のための措置について",
    "　",
    "事業者は、利用者等の人権の擁護・虐待の防止等のために、次に掲げるとおり必要な措置を講じます。",
    "（1） 虐待防止に関する責任者を選定しています。",
    "　　　虐待防止に関する責任者：上田　美香",
    "（2） 成年後見制度の利用を支援します。",
    "（3） 従業者に対する虐待防止を啓発･普及するための研修を実施しています。（年２回）",
    "（4） 事業所における虐待防止のための対策を検討する委員会（テレビ電話等を活用して行うことが出来るものとする）を定期的に開催するとともに、その結果に",
    "　　　ついて、従業員に周知徹底を図ります。",
    "（5） 事業所における虐待防止のための指針の整備をしています。",
    "（6） サービス提供中に、当該事業所従業者又は養護者（利用者の家族等高齢者を現に養護する者）による虐待を受けたと思われる利用者を発見した場合は、速やか",
    "　　　に、これを市町村に通報します。",
    "８　身体拘束の禁止について",
    "（1） 指定訪問看護の提供にあたっては、当該利用者又は他の利用者等の生命又は身体を保護するため緊急やむを得ない場合を除き、身体拘束その他利用者の行動を",
    "　　　制限する行為を行いません。",
    "（2） 前号の身体的拘束を行う場合には、その態様及び時間、その際の利用者の心身の状況並びに緊急やむを得ない理由を記録いたします。",
  ],
 
};
