import {
  pageInfoDisclosureStatement,
  topicDisclosureStatement,
} from "../../PageData/DisclosureStatement/DisclosureStatementPage";
import { GridLayout, PageBodyContainer, Topic } from "../../components";
const DisclosureStatement = () => {
  return (
    <PageBodyContainer pageInfo={pageInfoDisclosureStatement}>
      <GridLayout md={[12]} spacing={6} style={{ marginTop: 32 }}>
        {<Topic topic={topicDisclosureStatement} id={`disclosureTopic`}></Topic>}
      </GridLayout>
    </PageBodyContainer>
  );
};

export default DisclosureStatement;
