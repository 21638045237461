import { routePath } from "../../routes/routePath";
import Image1 from "./main-01.jpg";
import Image2 from "./main-02.jpg";
import Image3 from "./main-03.jpg";

export const SlideShowImage = [Image1, Image2, Image3];
export const pageTitle = "ホームページ";
export const intro = {
  title: "ごあいさつ",
  content: [
    "平素より格別のお引き立てを、ありがとうございます。",
    "われわれは、安心・安全・高品質なサービスを追求し、",
    "ご利用者様はもちろんのこと、",
    "ご家族の方々にもご満足いただけるサービスを目指してまいります。",
    "　",
    "今後とも、みなさまのご期待に応えるべく、全社員で邁進していく所存であります。",
  ],
};

export const homePageButtonsList = [
  {
    text: "ご利用案内",
    icon: "Info",
    bgcolor: "#c1f587",
    url: routePath.info,
  },
  {
    text: "採用情報",
    icon: "Recruit",
    bgcolor: "#c6a7fc",
    url: routePath.recruit,
  },
];

export const tabList = [
  {
    name: "全てのお知らせ",
    bgColor: "#c1f587",
  },
  {
    name: "会社からのお知らせ",
    bgColor: "#c1f587",
  },
];

export const newList = [
  {
    group: 1,
    date: "2025年3月31日",
    content: ["利用料金の一部を改訂いたしました。",
   "",
   "サイトマップに「医療DX推進体制」、「重要事項説明」を追加いたしました。",
    ]
  },
  {
    group: 1,
    date: "2024年4月28日",
    content: "ホームページを公開いたしました。",
  },

];
