import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  GridLayout,
  TableCellCustom,
  TypographyCustom,
} from "../../components";
import { routePath } from "../../routes/routePath";

export const pageInfoLongTermCareInsurance = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "ご利用案内", url: routePath.info },
    { name: "利用料金", url: routePath.info, id: "infoPageTopic1" },
    { name: "介護保険", url: routePath.longTermCareInsurance },
  ],
  pageHeader: "介護保険",
};

const style = {
  headerCell: {
    backgroundColor: "#F3EEEA",
  },
};

const table1 = [
  ["訪問看護Ⅰ1", "20分未満", "314単位", "336円", "672円", "1,008円"],
  ["訪問看護Ⅰ2", "30分未満", "471単位", "504円", "1,008円", "1,512円"],
  ["訪問看護Ⅰ3", "30分以上60分未満", "823単位", "881円", "1,762円", "2,642円"],
  [
    "訪問看護Ⅰ4",
    "60分以上90分未満",
    "1,128単位",
    "1,207円",
    "2,414円",
    "3,621円",
  ],
];

const table2 = [
  ["予防訪問看護Ⅰ1", "20分未満", "303単位", "325円", "649円", "973円"],
  ["予防訪問看護Ⅰ2", "30分未満", "451単位", "483円", "965円", "1,448円"],
  [
    "予防訪問看護Ⅰ3",
    "30分以上60分未満",
    "794単位",
    "850円",
    "1,699円",
    "2,549円",
  ],
  [
    "予防訪問看護Ⅰ4",
    "60分以上90分未満",
    "1,090単位",
    "1,167円",
    "2,333円",
    "3,499円",
  ],
];

const table3 = [
  ["初回加算Ⅰ", "初めて訪問を行った日が退院当日の場合", "350単位", "375円", "749円", "1124円"],
  ["初回加算Ⅱ", "初めて訪問を行った場合、もしくは要介護→要支援、または要支援→要介護へ変更になった場合", "300単位", "321円", "642円", "963円"],
  ["退院時共同指導加算", "月1～2回加算", "600単位", "642円", "1,284円", "1,926円"],
  ["緊急時訪問看護加算（Ⅱ）", "月1回", "574単位", "614円", "1,228円", "1,843円"],
  ["特別管理加算（Ⅰ）", "月1回", "500単位", "535円", "1,070円", "1,605円"],
  ["特別管理加算（Ⅱ）", "月1回", "250単位", "268円", "535円", "803円"],
  [
    //{ line1: "複数名訪問加算", line2: "（看護師と看護師等の場合）" },
    <>
      複数名訪問加算（Ⅰ）
      <br />
      （看護師と看護師等の場合）
    </>,
    "30分未満",
    "254単位",
    "272円",
    "544円",
    "815円",
  ],
  ["30分以上", "402単位", "430円", "860円", "1,290円"],
  [
    //{ line1: "複数名訪問加算", line2: "（看護師と看護補助者の場合）" },
    <>
      複数名訪問加算（Ⅱ）
      <br />
      （看護師と看護補助者の場合）
    </>,
    "30分未満",
    "201単位",
    "215円",
    "430円",
    "645円",
  ],
  ["30分以上", "317単位", "339円", "678円", "1,018円"],
  [
    "長時間訪問看護加算",
    "90分以上の訪問1回につき",
    "300単位",
    "321円",
    "642円",
    "963円",
  ],
  [
    //{ line1: "ターミナルケア加算" },
    <>
      ターミナルケア加算
    </>,
    "ターミナルケアを行った場合",
    "2,500単位",
    "2,675円",
    "5,350円",
    "8,025円",
  ],
  [
    //{ line1: "看護・介護職員連携強化加算" },
    <>
      看護・介護職員連携強化加算
    </>,
    "1回につき",
    "250単位",
    "268円",
    "535円",
    "803円",
  ],
  [
    //{ line1: "定期巡回・随時対応訪問介護看護と連携（要介護5の場合）" },
    <>
      定期巡回・随時対応訪問介護看護と連携（要介護5の場合）
    </>,
    "",
    "800単位",
    "856円",
    "1,712円",
    "2,568円",
  ],
];

export const topicLongTermCareInsurance = [
  {
    header: "介護保険",
    type: "custom",
    content: (
      <GridLayout md={[12]} xs={[12]}>
        <TypographyCustom>〇早朝（6-8時）、夜間（18-22時）は25％増、深夜（22-6時）は50％増となります。</TypographyCustom>
        <TypographyCustom>〇1日3回以上理学療法士等による訪問看護（介護予防訪問看護）をする場合は1回につき90/100（50/100）を算定します。</TypographyCustom>
        <TypographyCustom>〇准看護師による訪問の場合、90/100を算定します。</TypographyCustom>
        <TypographyCustom>〇理学療法士等による介護予防訪問看護では利用開始の月から12月超となった場合には1回につき5単位減算となります。</TypographyCustom>
        <TypographyCustom>〇介護保険の給付範囲を超えたサービスについては、全額自己負担となります。</TypographyCustom>	
        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "900px", overflow: "scroll" }}>
            <TableHead>
              <TableRow>
                <TableCellCustom
                  width={300}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>介護サービス内容</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={200}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>備考</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={125}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>単位数</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom style={style.headerCell} colSpan={3}>
                  <TypographyCustom>ご利用料金</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>1割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>2割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>3割負担</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>【看護師による訪問看護】</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              {table1.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {row.map((cell, cellIndex) => {
                      return (
                        <TableCellCustom align="left" key={cellIndex}>
                          <TypographyCustom>{cell}</TypographyCustom>
                        </TableCellCustom>
                      );
                    })}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>
                    *介護保険の給付範囲を超えたサービス利用については、全額自己負担となります
                  </TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>*1単位＝10.7円</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "900px", overflow: "scroll" }}>
            <TableHead>
              <TableRow>
                <TableCellCustom
                  width={300}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>介護予防サービス内容</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={200}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>備考</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={125}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>単位数</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom style={style.headerCell} colSpan={3}>
                  <TypographyCustom>ご利用料金</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>1割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>2割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>3割負担</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>【看護師による訪問看護】</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              {table2.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {row.map((cell, cellIndex) => {
                      return (
                        <TableCellCustom align="left" key={cellIndex}>
                          <TypographyCustom>{cell}</TypographyCustom>
                        </TableCellCustom>
                      );
                    })}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>
                    *介護保険の給付範囲を超えたサービス利用については、全額自己負担となります
                  </TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>*1単位＝10.7円</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "900px", overflow: "scroll" }}>
            <TableHead>
              <TableRow>
                <TableCellCustom
                  width={300}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>サービス内容</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={200}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>備考</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={125}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>単位数</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom style={style.headerCell} colSpan={3}>
                  <TypographyCustom>ご利用料金</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>1割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>2割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>3割負担</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableHead>
            <TableBody>
              {table3.map((row, index) => {
                return index === 6 || index === 8 ? (
                  <TableRow key={index}>
                    {row.map((cell, cellIndex) => {
                      return (
                        <TableCellCustom
                          align="left"
                          key={cellIndex}
                          rowSpan={cellIndex === 0 ? 2 : 1}
                        >
                          <TypographyCustom>{cell}</TypographyCustom>
                        </TableCellCustom>
                      );
                    })}
                  </TableRow>
                ) : (
                  <TableRow key={index}>
                    {row.map((cell, cellIndex) => {
                      return (
                        <TableCellCustom align="left" key={cellIndex}>
                          <TypographyCustom>{cell}</TypographyCustom>
                        </TableCellCustom>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </GridLayout>
    ),
  },
];
