import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import {
  GridLayout,
  TableCellCustom,
  TypographyCustom,
} from "../../components";
import { routePath } from "../../routes/routePath";

export const pageInfoPrivacy = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "ご利用案内", url: routePath.info },
    { name: "プライベート看護", url: routePath.privacy },
  ],
  pageHeader: "プライベート看護",
};

export const buttonListPrivacyPage = [
  { name: "ご利用までの流れ", url: routePath.info },
  {
    name: "利用料金",
    url: routePath.info,
  },
  {
    name: "事業所案内",
    url: routePath.info,
  },
  {
    name: "プライベート看護",
    url: routePath.privacy,
  },
];

const privacyData = {
  content1: [
    "医療保険・介護保険制度の中での訪問看護では、利用者様の要望に応えられない時間や場所に対応させていただきます。",
    "ご本人様・ご家族の要望に合わせたオーダーメイドの看護の提供をすることで、生活の質を向上させ、",
    "住み慣れたご自宅でよりその方らしく暮らせるようになります。",
    "※詳細については直接お問い合わせください。",
  ],
  content2: [
    "",
    "【契約の目的】",
    "第1条",
    "訪問看護ステーショングリーンピースは（以下事業所という）はご契約者（利用者）がその有する能力に応じ可能な限り健康で自立した生活を営む",
    "ことができるよう在宅医療を推進し、快適な在宅療養が、継続できるようにする事を目的として、訪問看護サービスを提供します。",
    "また、訪問看護サービスを提供するに当たって、訪問看護計画書を作成し利用者または家族に説明し交付します。",
    "",
    "【契約期間】",
    "第2条",
    "この契約有効期間は、　　　年　　　月　　　日から1年間とします。",
    "尚、利用者から契約終了の申し入れがない場合には、本契約は自動更新されるものとします。",
    "",
    "【対象者】",
    "第3条",
    "対象者は訪問看護ステーショングリーンピース（医療保険または介護保険適用）を受けている方で、保険対象にならないサービスを求められる方とします。なお従来保険サービスを受けておられた方で健康管理を必要とされる方も対象となります。",
  ],
};

export const topicPrivacy = {
  header: "プライベート看護（保険外自費）",
  type: "custom",
  content: (
    <GridLayout md={[12]} xs={[12]}>
      <GridLayout md={[12]} xs={[12]}>
        <TypographyCustom
          marginRight={1}
          padding={4}
          style={{ border: "1px solid lightgray", borderRadius: "5px" }}
        >
          {privacyData.content1.map((row, index) => {
            return (
              <>
                {row} <br />
              </>
            );
          })}
        </TypographyCustom>
      </GridLayout>
      <TypographyCustom
        marginTop={2}
        fontWeight={"bold"}
        padding={1}
        style={{ background: "lightgreen", width: "fit-content" }}
      >
        ご利用料金：サービス提供時間×時間単位+消費税+交通費
      </TypographyCustom>
      <TypographyCustom marginTop={2} fontWeight={"bold"}>
        継続の利用… 「週1回以上・1か月以上」の長期間のご利用
      </TypographyCustom>
      <TableContainer style={{ marginBottom: "32px" }}>
        <Table style={{ width: "900px", overflow: "scroll" }}>
          <TableBody>
            <TableRow>
              <TableCellCustom width={"150px"}>
                <TypographyCustom>基本料金</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left">
                <TypographyCustom>
                  夜間・早朝
                  <br />
                  午後6時00分〜午後10時00分
                  <br />
                  午前6時～午前8時
                </TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left">
                <TypographyCustom>
                  土日・祝日
                  <br />
                  午前9時00分〜午後6時00分
                </TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left">
                <TypographyCustom>
                  深夜
                  <br />
                  午後10時00分〜午前6時00分
                </TypographyCustom>
              </TableCellCustom>
            </TableRow>
            <TableRow>
              <TableCellCustom>
                <TypographyCustom>7,000円/時間</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left">
                <TypographyCustom>8,750円/時間</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left">
                <TypographyCustom>7,700円/時間</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left">
                <TypographyCustom>9,450/時間</TypographyCustom>
              </TableCellCustom>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TypographyCustom marginTop={2} fontWeight={"bold"}>
        単発のご利用… 単発・短期間のご利用
      </TypographyCustom>
  <TableContainer style={{ marginBottom: "32px" }}>
    <Table style={{ width: "900px", overflow: "scroll" }}>
  <TableBody>
  <TableRow>
 <TableCellCustom width={"150px"}>
 <TypographyCustom>基本料金</TypographyCustom>
 </TableCellCustom>
<TableCellCustom align="left">
<TypographyCustom>
 夜間・早朝
 <br />
午後6時00分〜午後10時00分
 <br />
午前6時～午前8時
</TypographyCustom>
</TableCellCustom>
<TableCellCustom align="left">
<TypographyCustom>
 土日・祝日
<br />
 午前9時00分〜午後6時00分
 </TypographyCustom>
</TableCellCustom>
<TableCellCustom align="left">
 <TypographyCustom>
 深夜
 <br />
午後10時00分〜午前6時00分
</TypographyCustom>
</TableCellCustom>
</TableRow>
<TableRow>
<TableCellCustom>
<TypographyCustom>8,800円/時間</TypographyCustom>
</TableCellCustom>
 <TableCellCustom align="left">
 <TypographyCustom>11,000円/時間</TypographyCustom>
</TableCellCustom>
<TableCellCustom align="left">
<TypographyCustom>9,680円/時間</TypographyCustom>
 </TableCellCustom>
<TableCellCustom align="left">
<TypographyCustom>11,880/時間</TypographyCustom>
 </TableCellCustom>
 </TableRow>
</TableBody>
</Table>
</TableContainer>

      {/* <TypographyCustom marginTop={2} fontWeight={"bold"}>
        サービス内容
      </TypographyCustom>

      <TableContainer style={{ marginBottom: "32px" }}>
        <Table style={{ width: "max-content", overflow: "scroll" }}>
          <TableHead>
            <TableRow>
              <TableCellCustom width={"250px"}>
                <TypographyCustom>バイタルサイン測定</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom width={"200px"}>
                <TypographyCustom>内服管理</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom width={"350px"} colSpan={2}>
                <TypographyCustom>吸入</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom width={"150px"}>
                <TypographyCustom>胃瘻</TypographyCustom>
              </TableCellCustom>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>健康チェック</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>点滴</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>排液チューブ</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"250px"}>
                <TypographyCustom>経鼻チューブ</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom></TypographyCustom>
              </TableCellCustom>
            </TableRow>
            <TableRow>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>食事介助・清潔ケア全般</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>膀胱留置カテーテル</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom></TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>人工肛門</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>人工呼吸器</TypographyCustom>
              </TableCellCustom>
            </TableRow>
            <TableRow>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>入浴介助・排泄介助</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>膀胱留置カテーテル</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom></TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>人工肛門</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>人工呼吸器</TypographyCustom>
              </TableCellCustom>
            </TableRow>
            <TableRow>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>体位交換・移動介助</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>気管カニューレ</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom></TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>血糖測定</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>導尿</TypographyCustom>
              </TableCellCustom>
            </TableRow>
            <TableRow>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>ご家族への技術指導</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>ガーゼ交換</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom></TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>摘便</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>リハビリ介助</TypographyCustom>
              </TableCellCustom>
            </TableRow>
            <TableRow>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>がん終末期対応</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>浣腸</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>口鼻腔吸引</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>中心静脈栄養　永久気管孔</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left" width={"150px"}>
                <TypographyCustom>リハビリ介助</TypographyCustom>
              </TableCellCustom>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TypographyCustom>
        ※介護・医療保険の訪問看護サービスは、原則ご自宅などの生活の場での利用に限られますが、
        プライベート看護サービスでは自宅以外にも看護師がお伺いいたします。
        (通院同行・冠婚葬祭などのイベントへの付き添いも承ります。）
        ご相談ください。
      </TypographyCustom>

      <TypographyCustom marginTop={4} fontWeight={"bold"}>
        プライベート看護契約書
      </TypographyCustom>
      <TypographyCustom>
        {privacyData.content2.map((row, index) => {
          return (
            <>
              {row} <br />
            </>
          );
        })}
      </TypographyCustom> */}
    </GridLayout>
  ),
};
