import { routePath } from "../routes/routePath";

export const listNav = {
  companyInfo: {
    companyName: "訪問看護ステーション グリーンピース",
    otherInfo: [
      "住所: 〒732-0824 広島県広島市南区的場町2丁目6-3  JH的場301号室",
      "TEL: 082-909-2920",
      "FAX: 082-554-0833",
      "メールアドレス: greenpeace0612@gmail.com",
    ],
  },
  siteMap: {
    title: "サイトマップ",
    listSizeMap: [
      { linkName: "➢個人情報守秘義務", url: routePath.isms },
      { linkName: "➢運営規定", url: routePath.operating },
      { linkName: "➢医療DX推進体制", url: routePath.dx },
      { linkName: "➢重要事項説明", url: routePath.disclosure },
      { linkName: "➢リンク集", url: routePath.link },
    ],
  },
};
