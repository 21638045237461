import { routePath } from "../../routes/routePath";

export const pageInfoDX = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "医療DX推進体制", url: routePath.dx },
  ],
  pageHeader: "医療DX推進体制",
};

export const topicDX = {
  header: "医療DX推進体制に関する説明事項",
  content: [
    "当事業所はより質の高い看護を目指し、医療DX推進体制を整えております。",
    "健康保険情報と一体化したマイナンバーカードを通して、オンラインでの資格確認を行っています。取得した資格情報をもとに、電子処方箋システムや電子カルテ情報共有サービスとの情報連携を行い、医療情報を活用した訪問看護を提供します。",
    "　",
    "目的について：",
    "オンライン資格確認をはじめとする医療DX推進を通して、関係医療機関との情報連携を促進し、質の高い看護を提供するため。",
    "　",
    "個人情報の取扱いについて：",
    "個人情報保護委員会・厚生労働省「医療・介護関係事業者における個人情報の適切な取扱いのためのガイタンス」、厚生労働省「医療情報システムの安全管理に関するガイドライン」等の関係法令を遵守し、個人情報保護方針に基づいた適正な管理を行い、ご利用者様への看護サービスの提供以外の目的には使用いたしません。",
    "　",
    "資格情報の提供について：",
    "資格情報の提供は患者様及び代理人の同意に基づいて行われます。同意なしにオンライン資格確認を行うことはございません。",
    "　",
    "　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　訪問看護ステーショングリーンピース",
  ],
};
